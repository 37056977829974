import { useEffect, useState } from "react";
import { Dropdown, Text, Button, Checkbox, TextField } from "@loadsmart/miranda-react";
import { PBIReport } from "managed-analytics/domain/PBIReport";
import { SchedulerRepository } from 'managed-analytics/infra/SchedulerRepository';
import type { Scheduler } from 'managed-analytics/domain/Scheduler';
import { HttpClient } from 'core/infra/http/HttpClient';
import { usePBIReports } from 'managed-analytics/ui/hooks/usePBIReports';
import { registerAllTaskTypes, getTaskTypeConfig, getAllTaskTypes } from "./TaskTypeRegistry";
import { Dialog } from "@loadsmart/miranda-react";


import {
  StyledContainer,
  StyledInfoBox,
  StyledSubmitButton,
  StyledFieldWrapper,
  StyledAccordionHeader,
  StyledAccordionTitle,
  StyledAccordionIcon,
  StyledCheckboxGrid,
  StyledCheckboxLabel,
  StyledTimeList,
  StyledTimeItem,
  StyledTimeInput,
  StyledRemoveButton,
} from "./Scheduler.styles";

import CustomAccordion from "./CustomAccordion";

const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const monthDays = Array.from({ length: 31 }, (_, i) => (i + 1).toString());
const timezones = ["UTC", "EST", "CST", "MST", "PST"];

interface SchedulerFormProps {
  scheduleToEdit?: Scheduler;
  onSubmitComplete?: () => void;
}

const SchedulerForm = ({ scheduleToEdit, onSubmitComplete }: SchedulerFormProps) => {
  const { getReports, reports } = usePBIReports();
  const [selectedProfile, setSelectedProfile] = useState<string | undefined>();
  const [selectedReport, setSelectedReport] = useState<PBIReport | undefined>();
  const [scheduleTimes, setScheduleTimes] = useState<string[]>([]);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [recurrenceType, setRecurrenceType] = useState<"weekdays" | "monthdays">("weekdays");
  const [timezone, setTimezone] = useState<string>("UTC");
  const [exportType, setExportType] = useState<string | undefined>();
  const [activeAccordion, setActiveAccordion] = useState<string | null>("exportType");
  const [taskTypeFormData, setTaskTypeFormData] = useState<any>({});
  const [fullScheduleToEdit, setFullScheduleToEdit] = useState<Scheduler | undefined>(undefined);
  const [name, setName] = useState<string>("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);


  const httpClient = new HttpClient(import.meta.env.VITE_BASE_API_URL);
  const schedulerRepository = new SchedulerRepository({ httpClient });

  useEffect(() => {
    registerAllTaskTypes();
    getReports();
  }, [getReports]);

  const uniqueProfiles = Array.from(new Set(reports.map(report => report.roleName).filter(Boolean)));

  useEffect(() => {
    const fetchScheduleIfNeeded = async () => {
      if (scheduleToEdit && scheduleToEdit.uuid && !scheduleToEdit.eventConfig) {
        const fullSchedule = await schedulerRepository.getSchedule(scheduleToEdit.uuid);

        setFullScheduleToEdit(fullSchedule);
      } else if (scheduleToEdit) {
        setFullScheduleToEdit(scheduleToEdit);
      }
    };

    fetchScheduleIfNeeded();
  }, [scheduleToEdit]);

  useEffect(() => {
    if (recurrenceType === "weekdays") {
      setSelectedDays([]);
    } else {
      setSelectedDays([]);
    }
  }, [recurrenceType]);
  

  useEffect(() => {
    if (fullScheduleToEdit) {
      const {
        schedule,
        eventConfig: { report, days, times, timezone, exportType, ...customFields }
      } = fullScheduleToEdit;

      setRecurrenceType(schedule === "monthdays" ? "monthdays" : "weekdays");
      setName(fullScheduleToEdit.name ?? ""); 
      setSelectedReport(report);
      setSelectedProfile(report.roleName);
      setSelectedDays(days);
      setScheduleTimes(times);
      setTimezone(timezone);
      setExportType(exportType);
      setActiveAccordion("schedule");

      if (exportType && getTaskTypeConfig(exportType)) {
        const taskTypeConfig = getTaskTypeConfig(exportType);
        const deserialize = taskTypeConfig?.deserialize;
        if (deserialize) {
          setTaskTypeFormData(deserialize(customFields));
        }
      }
    } else if (reports.length > 0 && !selectedProfile) {
      setSelectedProfile(uniqueProfiles[0]);
    }
  }, [fullScheduleToEdit, reports]);

  const filteredReports = reports.filter(report => report.roleName === selectedProfile);

  useEffect(() => {
    if (selectedProfile && !fullScheduleToEdit) {
      setSelectedReport(filteredReports[0]);
    }
  }, [selectedProfile, reports, fullScheduleToEdit]);

  const handleExportTypeChange = (type: string) => {
    setExportType(type);
    setTaskTypeFormData({});
    toggleAccordion("content");
  };

  const toggleAccordion = (key: string) =>
    setActiveAccordion(prev => (prev === key ? null : key));

  const handleTimeAdd = () => setScheduleTimes([...scheduleTimes, ""]);

  const handleTimeChange = (index: number, value: string) => {
    const updated = [...scheduleTimes];
    updated[index] = value;
    setScheduleTimes(updated);
  };

  const handleTimeRemove = (index: number) =>
    setScheduleTimes(scheduleTimes.filter((_, i) => i !== index));

  const handleDayToggle = (day: string) => {
    setSelectedDays(prev =>
      prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]
    );
  };

  const handleSubmit = async () => {
    if (!selectedReport || !exportType) {
      alert('Please select export type and report.');
      return;
    }
  
    if (scheduleTimes.length === 0 || selectedDays.length === 0) {
      alert("Please select at least one day and one time.");
      return;
    }
  
    const taskTypeConfig = getTaskTypeConfig(exportType);
    if (!taskTypeConfig) {
      alert("Invalid export type selected.");
      return;
    }
  
    const validationErrors = taskTypeConfig.validate(taskTypeFormData);
    if (validationErrors.length > 0) {
      alert(validationErrors.join('\n'));
      return;
    }
  
    const customFields = taskTypeConfig.serialize(taskTypeFormData);
  
    const payload: Scheduler = {
      uuid: fullScheduleToEdit?.uuid,
      name,
      schedule: recurrenceType,
      userUuid: selectedReport?.userUuid || "",
      roleUuid: selectedReport?.roleUuid || "",
      eventConfig: {
        report: selectedReport,
        days: selectedDays,
        times: scheduleTimes,
        timezone,
        exportType,
        ...customFields,
      },
    };
  
    try {
      if (fullScheduleToEdit) {
        await schedulerRepository.updateSchedule(payload);
        onSubmitComplete?.();
      } else {
        await schedulerRepository.createSchedule(payload);
        setSuccessDialogOpen(true); 
      }
      
    } catch (error) {
      alert(`Failed to ${fullScheduleToEdit ? 'update' : 'create'} schedule: ${error}`);
    }
  };
  

  const renderTaskTypeForm = () => {
    if (!exportType) return null;
    const config = getTaskTypeConfig(exportType);
    if (!config) return null;
  
    return config.render({
      selectedReport,
      uniqueProfiles,
      filteredReports,
      selectedProfile,
      setSelectedProfile,
      setSelectedReport,
      taskTypeFormData,
      setTaskTypeFormData,
    });
  };
  

  return (
    <StyledContainer>
      <CustomAccordion
        title={
          <StyledAccordionHeader>
            <StyledAccordionIcon isOpen={activeAccordion === "exportType"} />
            <StyledAccordionTitle>Select Export Type</StyledAccordionTitle>
          </StyledAccordionHeader>
        }
        isOpen={activeAccordion === "exportType"}
        onClick={() => toggleAccordion("exportType")}
      >
        <StyledFieldWrapper>
        <Text>Schedule Name:</Text>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Give your schedule a name"
        />

      </StyledFieldWrapper>

      <StyledFieldWrapper>
        <Text>Select an Export Type:</Text>
        <Dropdown>
        <Dropdown.Trigger>
          {exportType ? getTaskTypeConfig(exportType)?.label : "Select an export type"}
        </Dropdown.Trigger>

          <Dropdown.Menu>
            {getAllTaskTypes().map((type) => {
              const config = getTaskTypeConfig(type);
              return (
                <Dropdown.Item key={type} onClick={() => handleExportTypeChange(type)}>
                  {config?.label || "Unknown"}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </StyledFieldWrapper>

      </CustomAccordion>

      <CustomAccordion
        title={
          <StyledAccordionHeader>
            <StyledAccordionIcon isOpen={activeAccordion === "content"} />
            <StyledAccordionTitle>Content</StyledAccordionTitle>
          </StyledAccordionHeader>
        }
        isOpen={activeAccordion === "content"}
        onClick={() => toggleAccordion("content")}
      >
        {(!name || !exportType) ? (
          <Text color="color-danger-60" variant="body-md">
            ⚠ Please enter a schedule name and select an export type before filling out the content.
          </Text>
        ) : (
          renderTaskTypeForm()
        )}
      </CustomAccordion>


      <CustomAccordion
        title={
          <StyledAccordionHeader>
            <StyledAccordionIcon isOpen={activeAccordion === "schedule"} />
            <StyledAccordionTitle>Schedule Task</StyledAccordionTitle>
          </StyledAccordionHeader>
        }
        isOpen={activeAccordion === "schedule"}
        onClick={() => toggleAccordion("schedule")}
      >
        <StyledFieldWrapper>
          <Text>Recurrence Type:</Text>
          <Dropdown>
            <Dropdown.Trigger>{recurrenceType === "weekdays" ? "Weekdays" : "Month Days"}</Dropdown.Trigger>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setRecurrenceType("weekdays")}>Weekdays</Dropdown.Item>
              <Dropdown.Item onClick={() => setRecurrenceType("monthdays")}>Month Days</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </StyledFieldWrapper>

        <StyledFieldWrapper>
          <Text>Timezone:</Text>
          <Dropdown>
            <Dropdown.Trigger>{timezone}</Dropdown.Trigger>
            <Dropdown.Menu>
              {timezones.map(zone => (
                <Dropdown.Item key={zone} onClick={() => setTimezone(zone)}>
                  {zone}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </StyledFieldWrapper>

        <StyledFieldWrapper>
        <Text>Select Days:</Text>
        {recurrenceType === "monthdays" && (
          <StyledInfoBox>
          <Text variant="body-sm" color="color-primary-60" style={{ marginBottom: "0.5rem" }}>
            If the selected day doesn’t exist in a given month (e.g. February 30 or months with no 31st),
            the task will run on the last day of that month.
          </Text>
        </StyledInfoBox>
        )}
        <StyledCheckboxGrid>
          {(recurrenceType === "weekdays" ? weekdays : monthDays).map(day => (
            <StyledCheckboxLabel key={day}>
              <Checkbox checked={selectedDays.includes(day)} onChange={() => handleDayToggle(day)}>
                {day}
              </Checkbox>
            </StyledCheckboxLabel>
          ))}
        </StyledCheckboxGrid>
      </StyledFieldWrapper>


        <StyledFieldWrapper>
          <Text>Select Times:</Text>
          {scheduleTimes.map((time, index) => (
            <StyledTimeList key={index}>
              <StyledTimeItem>
                <StyledTimeInput type="time" value={time} onChange={e => handleTimeChange(index, e.target.value)} />
                <StyledRemoveButton onClick={() => handleTimeRemove(index)}>✖</StyledRemoveButton>
              </StyledTimeItem>
            </StyledTimeList>
          ))}
          <Button onClick={handleTimeAdd}>➕ Add Time</Button>
        </StyledFieldWrapper>
      </CustomAccordion>
      <StyledInfoBox>
        <Text variant="body-sm" color="color-primary-60">
          Once scheduled, you'll receive the result of this task via email.
        </Text>
      </StyledInfoBox>

      <StyledSubmitButton variant="primary" onClick={handleSubmit}>
        {fullScheduleToEdit ? "Update Schedule" : "Schedule Task"}
      </StyledSubmitButton>
      <Dialog
        open={successDialogOpen}
        size="small"
        variant="success"
        onClose={() => {
          setSuccessDialogOpen(false);
          onSubmitComplete?.();
        }}
      >
        <Dialog.Header>
          Success
          <Dialog.Close />
        </Dialog.Header>
        <Dialog.Body>Your schedule was successfully created.</Dialog.Body>
        <Dialog.Actions>
          <Dialog.ActionPrimary
            onClick={() => {
              setSuccessDialogOpen(false);
              onSubmitComplete?.();
            }}
          >
            OK
          </Dialog.ActionPrimary>
        </Dialog.Actions>
      </Dialog>

    </StyledContainer>
  );
};

export default SchedulerForm;
