import React, { useRef, useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed } from 'powerbi-client';
import { Layout, SpinnerWheel } from '@loadsmart/miranda-react';
import { useAnalytics } from 'core/analytics/useAnalytics';

import {
    StyledPBIEmbedWrapper,
    StyledPageContainerDiv
} from './Report.styles';

interface ReportComponentProps {
    groupId: string;
    reportId: string;
    reportName: string | undefined;
    accessToken: string | undefined;
    isReportLoading: boolean;
}

const ReportComponent: React.FC<ReportComponentProps> = ({ reportName, groupId, reportId, accessToken, isReportLoading }) => {
    const currentReport = useRef<Report | null>(null);
    const { track } = useAnalytics();
    const lastSentEvent = useRef<Record<string, unknown> | null>(null);
    const lastPageChanged = useRef<string | null>(null);
    const isRegisteredFirstPageLoad = useRef(false);

    // Tracks embedded events while avoiding duplicates and navigating through the events noise
    const trackEvent = (eventData: Record<string, unknown>) => {
  
      const eventName = eventData['Event Type'] as string;
      const loadedPage = eventData.loadedPage as string;
  
      if (eventName === 'pageChanged') {
          if (!isRegisteredFirstPageLoad.current) {
              isRegisteredFirstPageLoad.current = true;
              eventData['Event Type'] = 'DefaultPageLoad';
          }
  
          if (loadedPage === lastPageChanged.current) {
              return;
          }
  
          lastPageChanged.current = loadedPage;
      }
  
      if (
          lastSentEvent.current &&
          JSON.stringify(lastSentEvent.current) === JSON.stringify(eventData)
      ) {
          return;
      }
  
      lastSentEvent.current = eventData;
  
      try {
          track('PBIEmbedEvent' as any, eventData)
      } catch (error) {
          console.error('Failed to send event to Mixpanel:', error);
      }
  };
  

    // Constructs the event data
    const buildEventData = (eventName: string, eventDetails: CustomEvent): Record<string, unknown> => {
        const eventData: Record<string, unknown> = {
            "Event Type": eventName,
            "Report Name": reportName,
            "Dataset Group Id": groupId,
            "Report Id": reportId,
            "Default Report": false,
        };

        if (eventName === 'dataSelected' && eventDetails.detail) {
            eventData.page = eventDetails.detail.page?.displayName;
            eventData.filters = eventDetails.detail.filters || [];
        }

        if (eventName === 'pageChanged' && eventDetails.detail) {
            eventData.loadedPage = eventDetails.detail.newPage?.displayName;
        }

        if (eventName === 'visualClicked' && eventDetails.detail) {
            eventData.page = eventDetails.detail.page?.displayName;
            eventData.filters = eventDetails.detail.filters || [];
            eventData.visualType = eventDetails.detail.visual?.type;
        }

        return eventData;
    };

    // Handles Power BI events
    const handleEvent = (eventName: string, eventDetails: CustomEvent) => {
        const eventData = buildEventData(eventName, eventDetails);
        trackEvent(eventData);
    };

    // Attaches listeners to the report
    const attachListeners = (report: Report) => {
        const powerBIEvents = ['dataSelected', 'pageChanged', 'visualClicked'];

        powerBIEvents.forEach((eventName) => {
            report.on(eventName, (details) => handleEvent(eventName, details));
        });

        return () => {
            powerBIEvents.forEach((eventName) => report.off(eventName));
        };
    };

    // Called when the Power BI report is initialized to attach event listeners
    const onEmbeddedComponentLoad = (embeddedComponent: Embed) => {

        if (currentReport.current) {
            isRegisteredFirstPageLoad.current = false;
            lastPageChanged.current = null;

            // Remove all previous event listeners
            ['dataSelected', 'pageChanged', 'visualClicked'].forEach((eventName) =>
                currentReport.current?.off(eventName)
            );
        }

        if ('bookmarksManager' in embeddedComponent) {
            currentReport.current = embeddedComponent as Report;
            attachListeners(currentReport.current);
        } else {
            console.error('The embedded component is not a valid Power BI Report.');
        }
    };

    // Cleanup event listeners on component unmount
    useEffect(() => {
        return () => {
            if (currentReport.current) {
                ['dataSelected', 'pageChanged', 'visualClicked'].forEach((eventName) =>
                    currentReport.current?.off(eventName)
                );
            }
        };
    }, []);

    return (
        <StyledPBIEmbedWrapper>
            {isReportLoading && (
                <StyledPageContainerDiv style={{ height: '70vh' }}>
                    <Layout.Group align="center" justify="center" style={{ flex: 1 }}>
                        <SpinnerWheel size="32px" />
                    </Layout.Group>
                </StyledPageContainerDiv>
            )}
            {!isReportLoading && (
                <PowerBIEmbed
                    embedConfig={{
                        accessToken,
                        groupId,
                        id: reportId,
                        tokenType: models.TokenType.Embed,
                        type: 'report',
                        settings: {
                            panes: {
                                pageNavigation: {
                                    visible: true,
                                    position: models.PageNavigationPosition.Left,
                                },
                            },
                            localeSettings: {
                                language: "en",
                                formatLocale: "en-US"
                            }
                        },
                    }}
                    cssClassName="pbi-report-class"
                    getEmbeddedComponent={(embeddedReport) => onEmbeddedComponentLoad(embeddedReport)}
                />
            )}
        </StyledPBIEmbedWrapper>
    );
};

export default ReportComponent;
