import { useEffect, useState } from 'react'
import axios from 'axios'

export const useFeatureFlags = () => {
  const [flags, setFlags] = useState<{ [key: string]: boolean }>({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchFlags = async () => {
      try {
        const response = await axios.get('/flags/', {
          withCredentials: true,
        })
        console.log('Feature Flags Response:', response.data)
        setFlags(response.data)
      } catch (error) {
        console.error('Failed to load feature flags:', error)
        setFlags({})
      } finally {
        setLoading(false)
      }
    }

    fetchFlags()
  }, [])

  return { flags, loading }
}
