import styled from "styled-components";
import { Text } from "@loadsmart/miranda-react";

interface CustomAccordionProps {
  title: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
  onClick: () => void;
}

const CustomAccordion = ({ title, children, isOpen, onClick }: CustomAccordionProps) => {
  return (
    <StyledAccordion>
      <StyledAccordionHeader onClick={onClick}>
        <Text>{title}</Text>
      </StyledAccordionHeader>
      <StyledAccordionContent isOpen={isOpen}>{children}</StyledAccordionContent>
    </StyledAccordion>
  );
};


export default CustomAccordion;

const StyledAccordion = styled.div`
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 10px;
`;

const StyledAccordionHeader = styled.div`
  background: #f5f5f5;
  padding: 10px;
  cursor: pointer;
`;

const StyledAccordionContent = styled.div<{ isOpen: boolean }>`
  padding: ${({ isOpen }) => (isOpen ? "10px" : "0px")};
  height: ${({ isOpen }) => (isOpen ? "auto" : "0px")};
  overflow: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: height 0.3s ease-in-out;
  background: white;
  position: relative;
`;
