import { HttpClientInterface, HttpClientConfig } from 'core/infra/http/HttpClient';
import { Scheduler } from 'managed-analytics/domain/Scheduler';
import { mapApiToSchedule, mapScheduleToApi, RawSchedule } from './SchedulerMapper';

export type SchedulerRepositoryDependencies = {
  httpClient: HttpClientInterface;
};

export class SchedulerRepository {
  private readonly httpClient: HttpClientInterface;

  constructor({ httpClient }: SchedulerRepositoryDependencies) {
    this.httpClient = httpClient;
  }

  async getSchedules(config?: HttpClientConfig): Promise<Scheduler[]> {
    const endpoint = '/schedules/';
    const { data } = await this.httpClient.get<RawSchedule[]>(endpoint, config);
    return data.map(mapApiToSchedule);
  }

  async createSchedule(schedule: Scheduler, config?: HttpClientConfig): Promise<Scheduler> {
    const endpoint = '/schedules/';
    console.log("Payload being sent to backend:", JSON.stringify(schedule, null, 2));

    const { data } = await this.httpClient.post<RawSchedule, RawSchedule>(
      endpoint,
      mapScheduleToApi(schedule),
      config
    );
    return mapApiToSchedule(data);
  }

  async updateSchedule(schedule: Scheduler, config?: HttpClientConfig): Promise<Scheduler> {
    if (!schedule.uuid) {
      throw new Error('Schedule UUID is required for updating.');
    }

    const endpoint = `/schedules/${schedule.uuid}/`;
    const { data } = await this.httpClient.put<RawSchedule, RawSchedule>(
      endpoint,
      mapScheduleToApi(schedule),
      config
    );
    return mapApiToSchedule(data);
  }

  async deleteSchedule(uuid: string, config?: HttpClientConfig): Promise<void> {
    const endpoint = `/schedules/${uuid}/`;
    await this.httpClient.delete(endpoint, config);
  }

  async getSchedule(uuid: string, config?: HttpClientConfig): Promise<Scheduler> {
    const endpoint = `/schedules/${uuid}/`;
    const { data } = await this.httpClient.get<RawSchedule>(endpoint, config);
    return mapApiToSchedule(data);
  }
  
}
