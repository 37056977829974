import styled from "styled-components";
import { Button, TextField, Text } from "@loadsmart/miranda-react";

export const StyledContainer = styled.div`
  width: 70%;
  margin: auto; /* centers both vertically and horizontally when used with flex parent */
  padding: 24px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
`;


export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
`;

export const StyledPaginationButton = styled(Button)`
  min-width: 100px;
  text-align: center;
  border-radius: 6px;
`;

export const StyledSearchField = styled(TextField)`
  width: 100%;
  max-width: 300px;
`;

export const PageInput = styled.input`
  width: 60px;
  padding: 6px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
`;

export const FullWidthButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  max-width: fit-content;
  white-space: nowrap;
  margin-left: 0;
`;

export const InfoTextAboveTable = styled(Text)`
  margin-bottom: 8px;
`;

export const RightAlignedControls = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
