import { useState, useEffect } from "react";
import { Table, Text, Dropdown } from "@loadsmart/miranda-react";
import {
  StyledContainer,
  SearchContainer,
  RightAlignedControls,
  PaginationContainer,
  StyledPaginationButton,
  StyledSearchField,
  PageInput,
  FullWidthButton,
  InfoTextAboveTable,
} from "./SchedulerHistory.styles";
import { useSchedulerHistory } from "managed-analytics/ui/hooks/useSchedulerHistory";
import { registerAllTaskTypes, getTaskTypeConfig } from "./TaskTypeRegistry";
import { DateTime } from "luxon";

const timezones = {
  UTC: "UTC",
  EST: "America/New_York",
  CST: "America/Chicago",
  MST: "America/Denver",
  PST: "America/Los_Angeles",
};

const ScheduleHistory = () => {
  const { executions, hasError } = useSchedulerHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(8);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginatedData, setPaginatedData] = useState<typeof executions>([]);
  const [timezoneLabel, setTimezoneLabel] = useState<keyof typeof timezones>("UTC");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    registerAllTaskTypes();
  }, []);

  const filteredExecutions = executions.filter(({ taskName, taskType, receiver, sentAt }) =>
    [taskName, taskType, receiver, sentAt].some((field) =>
      typeof field === "string" && (field as string).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    setPaginatedData(filteredExecutions.slice(start, end));
  }, [executions, currentPage, searchQuery, pageSize]);

  const totalPages = Math.ceil(filteredExecutions.length / pageSize);

  const handlePageInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPage = parseInt(e.target.value, 10);
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const formatDatetime = (isoDate: string | undefined) => {
    if (!isoDate) return "—";
    return DateTime.fromISO(isoDate, { zone: "utc" })
      .setZone(timezones[timezoneLabel])
      .toFormat("yyyy-MM-dd HH:mm:ss");
  };

  
  if (hasError) return <Text variant="body-md" color="color-danger-60">Error loading history.</Text>;

  return (
    <StyledContainer>
      <SearchContainer>
        <StyledSearchField
          placeholder="Search history..."
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setCurrentPage(1);
          }}
        />

        <RightAlignedControls>
          <Dropdown open={isDropdownOpen} onChange={() => setIsDropdownOpen((prev) => !prev)}>
            <Dropdown.Trigger>
              <Text variant="body-sm-bold">Timezone: {timezoneLabel}</Text>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              {Object.keys(timezones).map((tz) => (
                <Dropdown.Item
                  key={tz}
                  onClick={() => {
                    setTimezoneLabel(tz as keyof typeof timezones);
                    setIsDropdownOpen(false);
                  }}
                >
                  {tz}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </RightAlignedControls>
      </SearchContainer>

      <InfoTextAboveTable variant="body-sm" color="color-neutral-60">
        You're viewing the last 50 executed tasks.
      </InfoTextAboveTable>

      <Table size="default">
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>Task Name</Table.HeadCell>
            <Table.HeadCell>Task Type</Table.HeadCell>
            <Table.HeadCell>Executed At ({timezoneLabel})</Table.HeadCell>
            <Table.HeadCell>Action</Table.HeadCell>
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {paginatedData.length > 0 ? (
            paginatedData.map(({ uuid, taskName, taskType, sentAt, exportedArtefact }) => (
              <Table.Row key={uuid}>
                <Table.Cell>{taskName}</Table.Cell>
                <Table.Cell>{getTaskTypeConfig(taskType)?.label || taskType || "—"}</Table.Cell>
                <Table.Cell>{formatDatetime(sentAt)}</Table.Cell>
                <Table.Cell style={{ textAlign: "left" }}>
                  <FullWidthButton
                    variant="primary"
                    onClick={() => window.open(exportedArtefact, "_blank")}
                  >
                    Check content
                  </FullWidthButton>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <td colSpan={4} style={{ textAlign: "center" }}>
                No results found.
              </td>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <PaginationContainer>
        <StyledPaginationButton
          variant="secondary"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          ⬅ Previous
        </StyledPaginationButton>

        <Text variant="body-md-bold">Page {currentPage} of {totalPages}</Text>

        <PageInput
          type="number"
          min="1"
          max={totalPages}
          value={currentPage}
          onChange={handlePageInput}
        />

        <StyledPaginationButton
          variant="secondary"
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next ➡
        </StyledPaginationButton>
      </PaginationContainer>
    </StyledContainer>
  );
};

export default ScheduleHistory;
