import { useEffect, useState } from 'react';
import { Table, Text } from '@loadsmart/miranda-react';
import { Dialog } from '@loadsmart/miranda-react';
import { useSchedules } from 'managed-analytics/ui/hooks/useScheduler';
import { HttpClient } from 'core/infra/http/HttpClient';
import { SchedulerRepository } from 'managed-analytics/infra/SchedulerRepository';
import { getTaskTypeConfig } from './TaskTypeRegistry';
import {
  StyledContainer,
  SearchContainer,
  PaginationContainer,
  StyledPaginationButton,
  StyledSearchField,
  PageInput,
  FullWidthButton
} from './SchedulerHistory.styles';
import { Scheduler } from 'managed-analytics/domain/Scheduler';

interface SchedulerListProps {
    onSelect?: (uuid: string) => void;
}

const SchedulerList = ({ onSelect }: SchedulerListProps) => {
  const { schedules, getSchedules, hasError } = useSchedules();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState<Scheduler[]>([]);
  const [paginatedData, setPaginatedData] = useState<Scheduler[]>([]);
  const [confirmDelete, setConfirmDelete] = useState<Scheduler | null>(null);
  const [feedback, setFeedback] = useState<{ type: 'success' | 'error'; message: string } | null>(null);

  const pageSize = 8;
  const totalPages = Math.ceil(filteredData.length / pageSize);

  const httpClient = new HttpClient(import.meta.env.VITE_BASE_API_URL);
  const schedulerRepo = new SchedulerRepository({ httpClient });

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  useEffect(() => {
    const query = searchQuery.toLowerCase();
    const filtered = schedules.filter(s =>
      (s.eventConfig?.report?.reportName ?? '').toLowerCase().includes(query) ||
      (s.schedule ?? '').toLowerCase().includes(query) ||
      (s.eventConfig?.exportType ?? '').toLowerCase().includes(query)
    );
    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchQuery, schedules]);

  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    setPaginatedData(filteredData.slice(start, end));
  }, [currentPage, filteredData]);

  const handlePageInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const page = parseInt(e.target.value, 10);
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleDelete = async () => {
    if (!confirmDelete) return;

    try {
      await schedulerRepo.deleteSchedule(confirmDelete.uuid ?? '');
      setFeedback({ type: 'success', message: 'Schedule deleted successfully.' });
      getSchedules();
    } catch (err) {
      setFeedback({ type: 'error', message: 'Error deleting schedule.' });
    } finally {
      setConfirmDelete(null);
    }
  };

  if (hasError) {
    return <Text variant="body-md" color="color-danger-60">Failed to load scheduled tasks.</Text>;
  }

  const formatRecurrence = (value: string) => {
    switch (value) {
      case 'weekdays':
        return 'Week Days';
      case 'monthdays':
        return 'Month Days';
      default:
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
  };
  

  return (
    <StyledContainer>
      <SearchContainer>
        <StyledSearchField
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </SearchContainer>

      <Table size="default">
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Recurrence</Table.HeadCell>
            <Table.HeadCell>Days</Table.HeadCell>
            <Table.HeadCell>Times</Table.HeadCell>
            <Table.HeadCell>Export Type</Table.HeadCell>
            <Table.HeadCell>Action</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
            {paginatedData.length > 0 ? (
                paginatedData.map((scheduler) => (
                <Table.Row key={scheduler.uuid}>
                    <Table.Cell>{scheduler.name || 'Unnamed Task'}</Table.Cell>
                    <Table.Cell>{formatRecurrence(scheduler.schedule)}</Table.Cell>
                    <Table.Cell>{scheduler.eventConfig?.days?.join(', ') || '—'}</Table.Cell>
                    <Table.Cell>{scheduler.eventConfig?.times?.join(', ') || '—'}</Table.Cell>
                    <Table.Cell>
                    {getTaskTypeConfig(scheduler.eventConfig?.exportType)?.label || scheduler.eventConfig?.exportType || '—'}
                    </Table.Cell>
                    <Table.Cell>
                    <FullWidthButton
                        variant="danger"
                        onClick={() => onSelect?.(scheduler.uuid ?? '')}
                    >
                        Delete
                    </FullWidthButton>
                    </Table.Cell>
                </Table.Row>
                ))
            ) : (
                <Table.Row>
                <td colSpan={6} style={{ textAlign: 'center' }}>
                    No results found.
                </td>
                </Table.Row>
            )}
            </Table.Body>

      </Table>

      <PaginationContainer>
        <StyledPaginationButton
          variant="secondary"
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          ⬅ Previous
        </StyledPaginationButton>

        <Text variant="body-md-bold">Page {currentPage} of {totalPages}</Text>

        <PageInput
          type="number"
          min="1"
          max={totalPages}
          value={currentPage}
          onChange={handlePageInput}
        />

        <StyledPaginationButton
          variant="secondary"
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next ➡
        </StyledPaginationButton>
      </PaginationContainer>

      {/* Confirmation Dialog */}
      <Dialog
        open={!!confirmDelete}
        size="small"
        variant="danger"
        onClose={() => setConfirmDelete(null)}
      >
        <Dialog.Header>
          Confirm Deletion
          <Dialog.Close />
        </Dialog.Header>
        <Dialog.Body>
          Are you sure you want to delete the schedule{' '}
          <strong>{confirmDelete?.eventConfig?.report?.reportName}</strong>?
        </Dialog.Body>
        <Dialog.Actions>
          <Dialog.ActionSecondary onClick={() => setConfirmDelete(null)}>Cancel</Dialog.ActionSecondary>
          <Dialog.ActionPrimary onClick={handleDelete}>Delete</Dialog.ActionPrimary>
        </Dialog.Actions>
      </Dialog>

      {/* Feedback Dialog */}
      <Dialog
        open={!!feedback}
        size="small"
        variant={feedback?.type === 'success' ? 'success' : 'danger'}
        onClose={() => setFeedback(null)}
      >
        <Dialog.Header>
          {feedback?.type === 'success' ? 'Success' : 'Error'}
          <Dialog.Close />
        </Dialog.Header>
        <Dialog.Body>{feedback?.message}</Dialog.Body>
        <Dialog.Actions>
          <Dialog.ActionPrimary onClick={() => setFeedback(null)}>OK</Dialog.ActionPrimary>
        </Dialog.Actions>
      </Dialog>
    </StyledContainer>
  );
};

export default SchedulerList;
