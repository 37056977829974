import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const FIAIRedirect = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Convert search params to an object
    const paramsObject = Object.fromEntries(searchParams);

    // Store the parameters in localStorage
    localStorage.setItem('fiai_redirect_params', JSON.stringify(paramsObject));

    // Redirect to /login after a short delay
    const timer = setTimeout(() => {
      navigate('/login', { replace: true });
    }, 1000);

    return () => clearTimeout(timer);
  }, [navigate, searchParams]);

  return null;
};

export default FIAIRedirect;
