import { Route, Routes } from 'react-router-dom'

import { useSettings } from '@loadsmart/react-frontend-settings'

import LoginPage from 'auth/ui/LoginPage'
import LoginThroughBIP from 'auth/ui/LoginThroughBIP'
import LoginThroughBIPCallback from 'auth/ui/LoginThroughBIPCallback'
import DemoPage from 'demo/ui/DemoPage'
import HomePage from 'home/ui/HomePage'
import ManagedAnalyticsPage from 'managed-analytics/ui/ManagedAnalyticsPage'
import FIAIRedirect from 'auth/ui/FIAIRedirect'


import MobileNotSupported from './components/mobile/MobileNotSupported'

const Router = () => {
  const {
    isLoading,
    values: [isLoginThroughBIPEnabled],
  } = useSettings(['flags.ENABLE_LOGIN_THROUGH_BIP'])

  return isLoading ? null : (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {isLoginThroughBIPEnabled ? (
        <>
          <Route path="/login" element={<LoginThroughBIP />} />
          <Route path="/login-callback" element={<LoginThroughBIPCallback />} />
        </>
      ) : (
        <Route path="/login" element={<LoginPage />} />
      )}
      <Route path="/login-lmt" element={<LoginPage />} />
      <Route path="/analytics" element={<ManagedAnalyticsPage />} />
      <Route path="/demo" element={<DemoPage />} />
      <Route path="/mobile-not-supported" element={<MobileNotSupported />} />
      <Route path="/fiai_redirect" element={<FIAIRedirect />} />
    </Routes>
  )
}

export default Router
