import { useEffect, useState } from "react";
import { HttpClient } from "core/infra/http/HttpClient";

export const useSchedulerHistory = () => {
  const [executions, setExecutions] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);

  const httpClient = new HttpClient(import.meta.env.VITE_BASE_API_URL);

  const fetchExecutions = async () => {
    try {
      const response = await httpClient.get("/schedules/?history=true");
      setExecutions(response.data as any[]);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExecutions();
  }, []);

  return { executions, isLoading, hasError };
};
