import { Scheduler } from 'managed-analytics/domain/Scheduler';
import { PBIReport } from 'managed-analytics/domain/PBIReport';

export type RawSchedule = {
  uuid?: string;
  name: string;
  user: string;
  role: string;
  schedule: string;
  event_config: {
    days: string[];
    times: string[];
    timezone: string;
    exportType?: string;
    report?: PBIReport;
  };
};

export const mapApiToSchedule = (raw: RawSchedule): Scheduler => ({
  uuid: raw.uuid,
  name: raw.name,
  userUuid: raw.user,
  roleUuid: raw.role,
  schedule: raw.schedule,
  eventConfig: {
    ...raw.event_config,
    report: raw.event_config?.report ?? undefined,
  },
});

export const mapScheduleToApi = (schedule: Scheduler): RawSchedule => {
  const { report, days, times, timezone, exportType } = schedule.eventConfig;

  return {
    uuid: schedule.uuid,
    name: schedule.name,
    user: schedule.userUuid,
    role: schedule.roleUuid,
    schedule: schedule.schedule,
    event_config: {
      days,
      times,
      timezone,
      exportType,
      report,
    },
  };
};
