import { Dropdown, Text, TextField } from '@loadsmart/miranda-react'
import {
  StyledFieldWrapper,
} from '../../scheduler/Scheduler.styles'
import { PBIReport } from 'managed-analytics/domain/PBIReport'

interface Props {
  selectedReport: PBIReport | undefined;
  uniqueProfiles: string[];
  filteredReports: PBIReport[];
  selectedProfile: string | undefined;
  setSelectedProfile: (v: string) => void;
  setSelectedReport: (r: PBIReport) => void;
  formData: {
    file_format?: string;
  };
  setFormData: (data: any) => void;
}

export const ReportScreenshotForm = ({
  selectedReport,
  uniqueProfiles,
  filteredReports,
  selectedProfile,
  setSelectedProfile,
  setSelectedReport,
  formData = {},
  setFormData,
}: Props) => {
  return (
    <>
      <StyledFieldWrapper>
        <Text>Select a Profile:</Text>
        <Dropdown>
          <Dropdown.Trigger>{selectedProfile || 'Select a profile'}</Dropdown.Trigger>
          <Dropdown.Menu>
            {uniqueProfiles.map(profile => (
              <Dropdown.Item key={profile} onClick={() => setSelectedProfile(profile)}>
                {profile}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </StyledFieldWrapper>

      <StyledFieldWrapper>
        <Text>Select a Report:</Text>
        <Dropdown>
          <Dropdown.Trigger>{selectedReport?.reportName || 'Select a report'}</Dropdown.Trigger>
          <Dropdown.Menu>
            {filteredReports.map(report => (
              <Dropdown.Item key={report.reportId} onClick={() => setSelectedReport(report)}>
                {report.reportName}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </StyledFieldWrapper>

      <StyledFieldWrapper>
        <Text>File Format:</Text>
        <TextField
          value={formData.file_format ?? ''}
          placeholder="PDF, PNG, etc."
          onChange={e => setFormData({ ...formData, file_format: e.target.value })}
        />
      </StyledFieldWrapper>
    </>
  );
};
