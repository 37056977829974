import { useState, useCallback } from 'react'
import { SchedulerRepository } from 'managed-analytics/infra/SchedulerRepository'
import { Scheduler } from 'managed-analytics/domain/Scheduler'
import { LocalStorageClient } from 'core/infra/storage/LocalStorageClient'
import { TOKEN_STORAGE_USER_KEY } from 'auth/domain/User'
import { HttpClient } from 'core/infra/http/HttpClient'
import { RequestStatus } from 'core/domain/Request'

export function useSchedules() {
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Initial)
  const [schedules, setSchedules] = useState<Scheduler[]>([])

  const getSchedules = useCallback(async () => {
    console.log('Fetching schedules...');
    setStatus(RequestStatus.Pending);
  
    try {
      const storage = new LocalStorageClient();
      const token = storage.get(TOKEN_STORAGE_USER_KEY);
      const httpClient = new HttpClient(import.meta.env.VITE_BASE_API_URL);

      const repository = new SchedulerRepository({ httpClient });

      const result = await repository.getSchedules({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      console.log('Received schedules:', result);
  
      setSchedules(result);
      setStatus(RequestStatus.Done);
    } catch (err) {
      console.error('Failed to fetch schedules:', err);
      setStatus(RequestStatus.Error);
    }
  }, []);
  

  return {
    schedules,
    getSchedules,
    isLoading: status === RequestStatus.Pending,
    hasError: status === RequestStatus.Error,
  }
}
