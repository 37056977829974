import { JSX } from 'react';
import { ReportScreenshotForm } from './taskTypes/ReportScreenshotForm';

export interface TaskTypeConfig {
  label: string;
  render: (props: any) => JSX.Element;
  serialize: (formData: any) => Record<string, any>;
  deserialize: (data: Record<string, any>) => any;
  validate: (formData: any) => string[];
}

const registry: Record<string, TaskTypeConfig> = {};

export const registerTaskType = (type: string, config: TaskTypeConfig) => {
  registry[type] = config;
};

export const getTaskTypeConfig = (type: string): TaskTypeConfig | undefined => registry[type];

export const getAllTaskTypes = (): string[] => Object.keys(registry);

export const registerAllTaskTypes = () => {
  
  //This is a placeholder as example for future task types

  // registerTaskType('dataset', {
  //   label: 'Dataset Export',
  //   render: ({ taskTypeFormData, setTaskTypeFormData }) => (
  //     <DatasetForm formData={taskTypeFormData} setFormData={setTaskTypeFormData} />
  //   ),
  //   serialize: (data) => ({
  //     source: data.source,
  //     row_limit: data.row_limit,
  //   }),
  //   deserialize: (raw) => ({
  //     source: raw?.source ?? '',
  //     row_limit: raw?.row_limit ?? '',
  //   }),
  //   validate: (data) => {
  //     const errors: string[] = [];
  //     if (!data.source) errors.push('Source is required.');
  //     if (!data.row_limit) errors.push('Row limit is required.');
  //     else if (isNaN(Number(data.row_limit))) errors.push('Row limit must be a number.');
  //     return errors;
  //   },
  // });

  registerTaskType('report_screenshot', {
    label: 'Report Screenshot',
    render: ({
      selectedReport,
      selectedProfile,
      uniqueProfiles,
      filteredReports,
      setSelectedProfile,
      setSelectedReport,
      taskTypeFormData,
      setTaskTypeFormData,
    }) => (
      <ReportScreenshotForm
        selectedReport={selectedReport}
        selectedProfile={selectedProfile}
        uniqueProfiles={uniqueProfiles}
        filteredReports={filteredReports}
        setSelectedProfile={setSelectedProfile}
        setSelectedReport={setSelectedReport}
        formData={taskTypeFormData}
        setFormData={setTaskTypeFormData}
      />
    ),
    serialize: (formData) => ({
      file_format: formData.file_format ?? '',
    }),
    deserialize: (raw) => ({
      file_format: raw?.file_format ?? '',
    }),
    validate: (data) => {
      const errors: string[] = [];
      if (!data.file_format) errors.push('File format is required.');
      return errors;
    },
  });
};
