import styled from "styled-components";
import { Button } from "@loadsmart/miranda-react";


export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
  position: relative;

  /** 💡 Add these lines to limit height and enable scroll */
  max-height: 75vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;

  /* Optional: style scrollbar for webkit */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;


export const StyledDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
`;

export const StyledDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
`;

export const StyledDropdownItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  &:hover {
    background-color: #f0f0f0;
  }

  /* Miranda-Specific Dropdown Items */
  .miranda-dropdown-menu {
    width: 100% !important;
  }

  .miranda-dropdown-item {
    width: 100% !important;
    text-align: left;
  }
`;

export const StyledFieldWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

export const StyledAccordionHeader = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 14px 16px;
  cursor: pointer;
  border-radius: 6px;
  font-weight: bold;
  width: 100%;
  &:hover {
    background-color: #e0e0e0;
  }
`;

export const StyledAccordionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
`;

import { HiChevronRight } from "react-icons/hi";

export const StyledAccordionIcon = styled(HiChevronRight)<{ isOpen: boolean }>`
  font-size: 18px;
  transition: transform 0.5s ease-in-out;
  color: rgba(var(--m-color-primary-60, 0, 84, 84), 1);
  margin-right: 1em;
  transform: ${({ isOpen }) => (isOpen ? "rotate(90deg)" : "rotate(0deg)")};
`;


export const StyledAccordionTitle = styled.span`
  flex: 1;
  text-align: left;
`;

export const StyledCheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 8px;
  width: 100%;
  margin-top: 8px;
`;

export const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.2s;
  &:hover {
    background: #e0e0e0;
  }
`;

export const StyledTimeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 97%;
  margin-top: 8px;
`;

export const StyledTimeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 6px;
  width: 100%;
`;

export const StyledTimeInput = styled.input`
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
  width: 100%;
`;

export const StyledRemoveButton = styled.button`
  background: none;
  border: none;
  color: red;
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
  &:hover {
    color: darkred;
  }
`;

export const StyledSubmitButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`;

export const StyledInfoBox = styled.div`
  background-color: #f0f4ff;
  border: 1px solid #b3ccff;
  border-radius: 6px;
  padding: 0.75rem;
  margin: 0.5rem 0 1rem;
  color: #1a3b7c;
  font-size: 0.875rem;
`;
